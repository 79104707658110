import React, { useState } from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import videoStyle from "assets/jss/finwe/videoStyle.jsx";
import Cookies from "js-cookie";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

function Video({ ...props }) {
  const { classes, className, videoSrcURL, videoTitle, aspectRatio, ...rest } = props;
  const videoClasses = classNames({
    [classes.video]: true,
    [className]: className !== undefined
  });
  const location = useLocation();
  let intervalId = null;

  const cookieConsentName = "gatsby-gdpr-google-analytics";
  let consent = null;
  const getCookieConsentValue = (name) => {
    let cookieValue = Cookies.get(name);
    if (cookieValue === undefined) {
      cookieValue = Cookies.get(`${name}-legacy`);
    }
    return cookieValue;
  };
  const updateConsent = () => {
    consent = getCookieConsentValue(cookieConsentName);
    //console.log("Currently, cookie consent is " + consent);
    if (consent !== undefined) {
      //console.log("Stopping polling timer with ID=" + intervalId);
      clearInterval(intervalId); // stop polling if consent was given
    }
    return consent;
  }
  function listenCookieConsentChange(callback, interval = 1000) {
    if (typeof window === 'undefined') return; // In case of server side rendering
    let lastCookie = document.cookie;
    intervalId = setInterval( () => {
      //console.log("polling");
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({oldValue: lastCookie, newValue: cookie});
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
    //console.log("Polling timer set with ID=" + intervalId);
  }

  const renderContent = function(state) {
    switch (state) {
      case undefined:
        //console.log("Render: notification 'no cookie'");
        return (
          <div className={videoClasses}>
            <div className={classes.cookie}>
              <p>Please choose your cookie preference. Video player requires all cookies.</p>
            </div>
          </div>
        );
      case "false":
        //console.log("Render: notification 'cookie=false'");
        return (
          <div className={videoClasses}>
            <div className={classes.cookie}>
              <p>Minimal cookies enabled. Video player requires all cookies.</p>
              <button onClick={() => {
                Cookies.set(cookieConsentName, true);
                initializeAndTrack({ location });
                //setContent(renderContent(updateConsent()));
              }}>
                OK, allow all cookies
              </button>
            </div>
          </div>
        );
      case "true":
      default:
        //console.log("Render: video player");
        return (
          <div className={videoClasses} style={{paddingBottom: aspectRatio}}>
          <iframe className={classes.iframe}
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            controls="1"
            modestbranding="1"
            rel="0"
            {...rest}
          />
          </div>
        );
    }
  }
  
  const [content, setContent] = useState(renderContent(updateConsent()));
  
  if (consent !== "true") {
    listenCookieConsentChange(({oldValue, newValue})=> {
      //console.log(`Cookie changed from "${oldValue}" to "${newValue}"`);
      setContent(renderContent(updateConsent()));
    }, 1000);
  }

  return (
    <div>
      { content }
    </div>
  );
}

Video.defaultProps = {
  videoTitle: "",
  aspectRatio: "56.25%"
};

Video.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  aspectRatio: PropTypes.string,
};

export default withStyles(videoStyle)(Video);
