const videoStyle = {
  video: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: "25px",
    height: "0",
  },
  iframe: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  cookie: {
    display: "table",
    margin: "0 auto",
    border: "1px solid gray",
    padding: "20px",
  },
};

export default videoStyle;
